import styled from "styled-components";

import { p, h4 } from "theme/typography";
import { breakpoints } from "utils/mixins";
import { black, white } from "theme/colors";

export const CookieContainer = styled.div`
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 0;
  padding: 10px;
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    color: white;
    p {
      max-width: 100%;
      text-align: left;
    }
    a {
      ${p}
      text-decoration: underline;
    }
    button {
      cursor: pointer;
      ${h4}
      background-color: ${white.default};
      color: ${black.default};
      padding: 10px 15px;
      text-transform: uppercase;
    }
  }
  @media ${breakpoints.laptop} {
    .container {
      flex-wrap: nowrap;
      justify-content: center;
      justify-content: space-between;
      p {
        max-width: calc(100% - 300px);
        padding: 26px;
      }
    }
  }
`;
