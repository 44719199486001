import React from "react";
import { Helmet } from "react-helmet";

const CreateHead = (props) => {
  const headInfo = {
    title: props.title ? props.title : "Adalmáris Mobiliário",
    description: props.description
      ? props.description
      : "A Adalmáris está implementada no mercado do mobiliário e carpintaria há mais de 15 anos, contando já com uma vasta experiência e credibilidade no mercado.",
    url: props.url ? props.url : "https://adalmaris.pt",
    image: props.img ? props.img : "/bannerseo.jpg",
  };

  return (
    <Helmet>
      <html lang={props.lang === "PT" ? "pt" : "es"} amp />
      <title>{headInfo.title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content={props.keywords} />
      <meta name="description" content={headInfo.description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />
      <meta property="og:site_name" content={headInfo.title} />
      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:image:alt" content={headInfo.title}></meta>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link href="/fonts/stylesheet.css" rel="stylesheet" />
      {props.children}
    </Helmet>
  );
};
export default CreateHead;
