import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { toRem } from "utils/mixins";
import { blue, brown, white } from "./colors";
import { container } from "./sizes";
import { p, h1, h2, h3, h4 } from "./typography";

export const GlobalStyles = createGlobalStyle`
  ${normalize}


  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  b {
    font-weight: bold;
  }
  
  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
    background: ${white.default};

  }

  body {
    visibility: hidden;
    opacity: 0;
    width: 100%!important;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: ${toRem(20)};
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    color: black;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
    &.noover {
      overflow: hidden;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
  color: black!important;
  border: 1px solid transparent;
}
  

  .main {
    padding-top: ${toRem(100)};
    padding-bottom: ${toRem(25)};
    min-height: 100vh;
    position: relative;
  }

  .container {
    width: 100%;
    max-width: ${container.default};
    margin: 0 auto;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    &--small {
      max-width: ${container.small};
    }

    &--800 {
      max-width: ${toRem(800)};
    }

    &--xsmall {
      max-width: ${container.xsmall};
    }

    .smedia__container{
      margin-top:${toRem(8)} ;
      align-items: center;
      justify-content: center;
      display: flex;
      li {
        margin: 0 ${toRem(8)};
        line-height: 10px;
      }
    }

    @media (min-width: 599px) {
      padding-left: 27px;
      padding-right: 27px;
    }
  }

  section {
    //min-height: calc(100vh - 70px);
    width: 100%; 
    @media (min-width: 959px) { 
      min-height: calc(100vh - 70px);
    }
    &.banner {
      background-color: black;     
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;
      .gatsby-image-wrapper{
        position: absolute;
        overflow: hidden;
        opacity: .8;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100%;
        div{
          padding-top:100vh!important;
        }
        img{
          object-fit: cover;
        }
      }
      .container {  
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h1 {
          opacity: 0;
          width: 100%;
          text-align: center;
          color:${white.default};
          margin: 0
        }

        @keyframes scroll_2 {
          0%   { transform: translateY(0); opacity: 1; }
          25%  { opacity: 1; }
          75%  { transform: translateY(.75em); opacity: 0; }
          100% { transform: translateY(0); opacity: 0; }
        }

        .example--2 {
          opacity: 0;
          position: absolute;
          left: 50%;
          bottom: 40px;
          transform: translateX(-50%);
        }

        .scroll-icon__dot {
          display: block;
          position: absolute;
          left: 50%;
          background: #fff;
          height: .3em;
          width: .3em;
          top: .6em;
          margin-left: -.15em;
          border-radius: 50%;
          transform-origin: top center;
          backface-visibility: hidden;
          animation: scroll_2 2s ease-out infinite;
        }

        // General icon style
        .scroll-icon {
          display: block;
          position: relative;
          height: 3em;
          width: 1.5em;
          border: .10em solid #fff;
          border-radius: 1em;
        }
      }
    }

    &.aboutus {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .text-wrapper {
        width: 100%;
        padding-top: 40px;
      }
      .image-wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%;
      }
      @media (min-width: 959px) {  
        .text-wrapper {
          width: 50%;
          padding: 0;
          padding-right: 100px;
        }
        .image-wrapper {
          padding: 0;
          width: 50%;
        }
      }
    }
    &.services {
      padding: 40px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${blue.default};
      .container {
        h1{
          color: white;
          margin-bottom: 40px;
        }
      }
      .services-wrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        &--box {
          padding-bottom: 40px;
          max-width: 100%;
          text-align: center;
          svg{
            height: 40px;
            width: 40px;
          }
          h2{
            color: ${brown.light};
          }          
          p {
            color: white;
          }
        }
      }
      @media (min-width: 959px) {
        .services-wrapper {
          &--box {
            max-width: 30%;
          }
        }
      }
    }
    &.production {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .text-wrapper {
        width: 100%;
        padding-bottom: 40px;
      }
      .image-wrapper {
        padding-top: 40px;
        width: 100%;
        padding-bottom: 80px;
        position: relative;
        .image1 {
          width: 60%;
          margin-bottom: 15%;
        }
        .image2 {
          position: absolute;
          right: 0;
          width: 60%;
          top: 30%;
          overflow: visible;
          &::before {
            content: "";
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            left: -10px;
            top: -10px;
            position: absolute;
            background-color: ${white.default};
          }
        }
      }
      @media (min-width: 959px) {
        .text-wrapper {
          width: 50%;
          padding-bottom: 40px;
          padding-left: 100px;
        }
        .image-wrapper {
          width: 50%; 
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    &.showroom {
      min-height: 100vh;
      .showroom--wrapper {
        position: relative;
        .gatsby-image-wrapper{
          margin: 15px;
          max-height: 60vh;
        }
      }

      a{
        position: absolute;
        bottom: 0;
        right: 15px;
        display: block;
        padding: 14px 28px;
        background-color: ${blue.default};
        color: ${white.default};
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 16px;
        transform: translateY(60px);
      }

      @media (min-width: 959px) {
        h3 {
          padding-top: 10px;
        }
        .showroom--wrapper {
          display: flex; 
          .gatsby-image-wrapper{
            max-height: 70vh;
          }
        }
      }
    }
    &.contact {
      padding: 20px 0;
      min-height: 80vh;
      width: 100%;
      min-height: unset;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      
      overflow: hidden;
      .contact-form {
        width: 100%;
        padding: 20px;
      }
      .contact-map {
        width: 100%;
        height: 400px;
      }
      @media (min-width: 959px) {
       min-height: 80vh;
        .contact-form {
          width: 50%;
        }
        .contact-map {
          width: 50%;
          height: 600px;
        }
      }
    }
    &.privacypolicy {
      padding-top: 143px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
  }

  h1, .h1 {
    ${h1}
  }

  h2, .h2 {
    ${h2}
  }

  h3, .h3 {
    ${h3}
  }

  h4{
    ${h4}
  }

  p, label {
    ${p}
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a { 
    ${h3};
    position: relative;
    font-weight: 300;
    color: ${white.default};
    color: inherit;
    text-decoration: none;
    transition: 250ms;
    span {
      font-weight: bold;
    }
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  address {
    font-style: normal;
  }


  .show {
    display: block;
  }

  .hidden {
    display: none;
  }

  .hide__mobile{
    @media (max-width: 1079px) {
      display: none;
    }
  }

  .show__mobile{
    display: none;
    @media (max-width: 1079px) {
      display: block;
    }
  }
`;
