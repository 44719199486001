export const white = {
  default: "#f5f5f5",
};

export const black = {
  default: "#373431",
};

export const brown = {
  default: "#282321",
  light: "#bca163",
};

export const blue = {
  default: "#001d30",
};
