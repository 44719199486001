import React, { useEffect, useState } from "react";

import gsap from "gsap";
import { AnimatePresence } from "framer-motion";

import { GlobalStyles } from "theme/global";

import Header from "components/header";
import Footer from "components/footer";
import CookieBar from "components/cookieBar";
import { black, brown, white } from "../theme/colors";
import CreateHead from "../components/createHeader/CreateHead";

import dataPT from "data/pt.json";
import dataES from "data/es.json";

//UTILITY
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
//UTILITY

const Layout = ({ location, ...props }) => {
  const {
    pageContext: { translations },
  } = props;
  //STATE

  const [menuState, setMenuState] = useState(false);
  const [dimensions, setDimension] = useState({
    height: "",
    width: "",
  });
  const [color, setColor] = useState(true);
  const [scrollTop, setScroll] = useState(true);
  const [scrollY, setScrollY] = useState("");
  const [load, setLoad] = useState(false);
  //STATE

  const tl = gsap.timeline();
  const toTop = () => {
    load &&
      tl
        .to(`#header`, {
          duration: 0.25,
          css: {
            backgroundColor: "white",
            padding: "24px 0 17px",
          },
        })
        .to(`#logo a`, {
          duration: 0.15,
          delay: -0.25,
          css: {
            color: brown.light,
          },
        })
        .to(`.menuToggle`, {
          duration: 0.15,
          delay: -0.25,
          css: {
            backgroundColor: black.default,
          },
        });
    load && setColor(true);
  };

  const toBot = () => {
    load &&
      tl
        .to(`#header`, {
          duration: 0.25,
          css: {
            backgroundColor: "transparent",
            padding: "44px 0 37px",
          },
        })
        .to(`#logo a`, {
          duration: 0.15,
          delay: -0.25,
          css: {
            color: white.default,
          },
        })
        .to(`.menuToggle`, {
          duration: 0.15,
          delay: -0.25,
          css: {
            backgroundColor: white.default,
          },
        });
    load && setColor(false);
  };

  useEffect(() => {
    scrollTop ? toBot() : toTop();
  }, [scrollTop]);

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      setScrollY(scrollY);
      scrollY > 100 ? setScroll(false) : setScroll(true);
      ticking = false;

      return;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    if (props.path === "/" || props.path === "/es/") {
      window.addEventListener("scroll", onScroll, true);
    }

    return () => {
      window.removeEventListener("scroll", onScroll, true);
    };
  }, [location]);

  //EFFECT
  useEffect(() => {
    gsap.to("body", {
      duration: 0.4,
      delay: 0.6,
      css: { visibility: "visible", opacity: 1 },
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    setDimension({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    setLoad(true);
    return () => {
      // toBot();
      setLoad(false);
    };
  }, [location]);
  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      dimensions: dimensions,
      color: color,
      setColor: setColor,
      setScroll: setScroll,
      data: props.path.charAt(1) === "e" ? dataES : dataPT,
    });
  });
  //CHILDREN MODIFIER

  return (
    <>
      <GlobalStyles />
      <CreateHead
        title={
          props.path.charAt(1) === "e" ? dataES.seo.title : dataPT.seo.title
        }
        description={
          props.path.charAt(1) === "e"
            ? dataES.seo.description
            : dataPT.seo.description
        }
        keywords={
          props.path.charAt(1) === "e"
            ? dataES.seo.keywords
            : dataPT.seo.keywords
        }
      />
      {load && (
        <>
          <Header
            scrollY={scrollY}
            lang={props.path.charAt(1) === "e" ? "ES" : "PT"}
            menuState={menuState}
            dimensions={dimensions}
            setMenuState={setMenuState}
            color={color}
            location={props.path}
            setColor={setColor}
          />
          <main>
            <AnimatePresence initial={false}>
              {childrenWithProps}
            </AnimatePresence>
          </main>
          <Footer lang={props.path.charAt(1) === "e" ? "ES" : "PT"} />

          <CookieBar lang={props.path.charAt(1) === "e" ? "ES" : "PT"} />
        </>
      )}
    </>
  );
};

export default Layout;
