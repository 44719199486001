import React from "react";

const TailorMade = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill={color}
        d="M48 376h16v48H48zM80 376h16v16H80zM80 408h16v16H80zM196 182c16 6 38 10 60 10s44-4 60-10c18-7 28-18 28-30s-10-23-28-30c-16-6-38-10-60-10s-44 4-60 10c-18 7-28 18-28 30s10 23 28 30zm60-54c44 0 72 14 72 24s-28 24-72 24-72-14-72-24 28-24 72-24z"
      />
      <path
        fill={color}
        d="M432 232h-8v-80c0-27-18-51-51-69-31-17-73-27-117-27s-86 10-117 27c-33 18-51 42-51 69v112c0 27 18 51 51 69a185 185 0 0023 11H24a8 8 0 00-8 8v96a8 8 0 008 8h408a64 64 0 0064-64v-96a64 64 0 00-64-64zM112 440H32v-80h80zm296-208h-58a185 185 0 0023-11c15-8 26-17 35-28zM147 97c29-16 68-25 109-25s80 9 109 25c28 15 43 35 43 55s-15 40-43 55c-29 16-68 25-109 25s-80-9-109-25c-28-15-43-35-43-55s15-40 43-55zm0 222c-28-15-43-35-43-55v-71c9 11 20 20 35 28l13 7v36h16v-30a234 234 0 0024 7v23h16v-20l24 3v49h16v-48h24v16h16v-16h24v16h16v-16h24v48h16v-48h24v16h16v-16h24v16h16v-13a48 48 0 01-16 93H256c-41 0-80-9-109-25zm333 73a48 48 0 01-48 48H128v-80h24v16h16v-16h24v16h16v-16h24v48h16v-48h24v16h16v-16h24v16h16v-16h24v48h16v-48h24v16h16v-16h24v16h16v-18a64 64 0 0032-20z"
      />
    </svg>
  );
};

export default TailorMade;
