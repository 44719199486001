import styled from "styled-components";

import { toRem } from "utils/mixins";

export const FooterWrapper = styled.footer`
  position: relative;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  padding: ${toRem(20)};
  transition: 250ms;
`;

export const Privacy = styled.div`
  width: 100%;
  margin-bottom: 20px;
  a {
    text-align: left;
    font-size: ${toRem(14)};
    font-weight: 400;
    line-height: ${toRem(14)};
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: 599px) {
    width: calc(100% / 3);
    margin-bottom: 0;
  }
`;

export const Copy = styled.p`
  width: 100%;
  text-align: center;
  font-size: ${toRem(10)};
  margin-bottom: 20px;
  font-weight: 400;
  line-height: ${toRem(12)};

  @media (min-width: 599px) {
    width: calc(100% / 3);
    margin-bottom: 0;
  }
`;

export const Smedia = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    margin: 0 ${toRem(10)};
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      height: ${toRem(28)};
      svg {
        height: ${toRem(20)};
      }
    }
  }
  @media (min-width: 599px) {
    width: calc(100% / 3);
    justify-content: flex-end;
  }
`;
