import React from "react";

const Office = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill={color}
        d="M476 120H286V10c0-6-4-10-10-10H36c-6 0-10 4-10 10v492c0 6 4 10 10 10h440c6 0 10-4 10-10V130c0-6-4-10-10-10zm-190 20h180v252H286zM126 492v-80h60v80zm70-100h-80c-6 0-10 4-10 10v90H46V20h220v472h-60v-90c0-6-4-10-10-10zm90 100v-80h180v80z"
      />
      <path
        fill={color}
        d="M146 80h20c6 0 10-4 10-10V50c0-6-4-10-10-10h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10zM146 150h20c6 0 10-4 10-10v-20c0-6-4-10-10-10h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10zM146 220h20c6 0 10-4 10-10v-20c0-6-4-10-10-10h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10zM146 290h20c6 0 10-4 10-10v-20c0-6-4-10-10-10h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10zM146 360h20c6 0 10-4 10-10v-20c0-6-4-10-10-10h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10zM76 80h20c6 0 10-4 10-10V50c0-6-4-10-10-10H76c-6 0-10 4-10 10v20c0 6 4 10 10 10zM76 150h20c6 0 10-4 10-10v-20c0-6-4-10-10-10H76c-6 0-10 4-10 10v20c0 6 4 10 10 10zM76 220h20c6 0 10-4 10-10v-20c0-6-4-10-10-10H76c-6 0-10 4-10 10v20c0 6 4 10 10 10zM76 290h20c6 0 10-4 10-10v-20c0-6-4-10-10-10H76c-6 0-10 4-10 10v20c0 6 4 10 10 10zM76 360h20c6 0 10-4 10-10v-20c0-6-4-10-10-10H76c-6 0-10 4-10 10v20c0 6 4 10 10 10zM236 40h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10V50c0-6-4-10-10-10zM236 110h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM236 180h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM236 250h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM236 320h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM421 180h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM421 250h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM421 320h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM351 180h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM351 250h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10zM351 320h-20c-6 0-10 4-10 10v20c0 6 4 10 10 10h20c6 0 10-4 10-10v-20c0-6-4-10-10-10z"
      />
    </svg>
  );
};

export default Office;
