import { css } from "styled-components";
import { toRem, breakpoints } from "utils/mixins";
import { brown, blue } from "./colors";

export const h1 = css`
  font-family: "Oregon LDO";
  font-size: ${toRem(50)};
  line-height: ${toRem(54)};
  font-weight: 300;
  margin: 0;
  line-height: normal;
  color: ${blue.default};
  @media (orientation: portrait) {
    font-size: ${toRem(45)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(40)};
  }
`;

export const h2 = css`
  font-family: "Oregon LDO";
  font-size: ${toRem(24)};
  font-weight: 300;
  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(24)};
  }
  @media (orientation: portrait) {
    font-size: ${toRem(24)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(24)};
  }
`;

export const h3 = css`
  color: ${brown.light};
  font-family: "Oregon LDO";
  font-size: ${toRem(20)};
  line-height: ${toRem(20)};
  font-weight: 500;
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(20)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(20)};
  }
`;

export const h4 = css`
  font-family: "Oregon LDO";
  font-size: ${toRem(18)};
  font-weight: 300;
  line-height: ${toRem(24)};
`;

export const p = css`
  font-family: "Open Sans", sans-serif;
  font-size: ${toRem(15)};
  line-height: ${toRem(18)};
  letter-spacing: 0.3px;
`;
