import React from "react";

const Truck = (props) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill={color}
        d="M119 337a51 51 0 101 103 51 51 0 00-1-103zm0 85a34 34 0 111-68 34 34 0 01-1 68zM410 337a51 51 0 100 103 51 51 0 000-103zm0 85a34 34 0 110-68 34 34 0 010 68z"
      />
      <path
        fill={color}
        d="M511 290l-77-120c-2-2-4-4-7-4h-94c-5 0-9 4-9 9v213c0 5 4 9 9 9h34v-17h-26V183h81l73 114v83h-43v17h51c5 0 9-4 9-9v-94l-1-4z"
      />
      <path
        fill={color}
        d="M375 277v-59h69v-17h-77c-5 0-9 3-9 8v77c0 5 4 8 9 8h128v-17H375zM333 107H9c-5 0-9 3-9 8v273c0 5 4 9 9 9h68v-17H17V124h307v256H162v17h171c5 0 8-4 8-9V115c0-5-3-8-8-8z"
      />
      <path
        fill={color}
        d="M9 346h51v17H9zM179 346h145v17H179zM469 346h34v17h-34zM34 141h299v17H34zM111 380h17v17h-17zM401 380h17v17h-17zM34 73h120v17H34zM0 73h17v17H0z"
      />
    </svg>
  );
};

export default Truck;
