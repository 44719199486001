import styled from "styled-components";

import { black, blue, white } from "theme/colors";

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  background-color: ${blue.default};
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 100px;
  transform: ${(props) =>
    props.menuState ? "translateY(0)" : "translateY(-100%)"};
  transition: 400ms;
  .menu {
    margin-right: 25px;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .getInTouch {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    /* background-color: red; */
    width: auto;
    text-align: center;
    color: white;
  }

  @media (min-width: 769px) {
    padding-top: 0;
    transform: translate(0);
    position: relative;
    width: auto;
    height: auto;
    background-color: transparent;
    z-index: 3;
    opacity: 1;
    transition: 0s;
    .menu {
      transition: 0s;
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }
    .getInTouch {
      display: none;
    }
  }
`;

export const NavColumn = styled.li`
  margin-left: 0;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (min-width: 769px) {
    margin-left: 5px;
    margin-bottom: 0;
    transition: 0s;
  }

  a,
  button {
    padding: 5px;
    text-transform: uppercase;
    font-family: "Oregon LDO";
    color: ${(props) => (props.scolor ? black.default : white.default)};
    letter-spacing: 0.8px;
    line-height: normal;
    position: relative;
    font-weight: 300;
    font-size: 26px;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      display: block;
      bottom: 0;
      right: 0;
      transition: 0.4s ease;
      background-color: ${(props) =>
        props.scolor ? black.default : white.default};
    }
    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }
    @media (min-width: 769px) {
      font-size: 14px;
      transition: 0s;
      color: ${(props) => (props.scolor ? black.default : white.default)};
      &::before {
        background-color: ${(props) =>
          props.scolor ? black.default : white.default};
      }
    }
  }
`;
