import React, { useEffect } from "react";
import { Wrapper, Toggle, Logo } from "./Header.styles";
import { Link } from "gatsby";

import Nav from "./nav";

const Header = (props) => {
  const {
    color,
    location,
    setColor,
    menuState,
    dimensions,
    setMenuState,
    scrollY,
    lang,
  } = props;

  useEffect(() => {
    setMenuState(false);
    return () => {
      setMenuState(false);
    };
  }, [location]);

  useEffect(() => {
    if (menuState) {
      setColor(false);
    }
    return () => {};
  }, [menuState, setColor]);

  console.log(props);

  return (
    <Wrapper
      scolor={color ? true : false}
      isHome={location === "/" || location === "/en"}
      id="header"
    >
      <div className="container">
        <Logo scolor={color ? true : false} id="logo">
          <Link to={lang === "PT" ? "/" : "/es/"}>
            Adalmaris<span>Mobiliário</span>
          </Link>
        </Logo>
        <div className="col">
          <Nav
            scrollY={scrollY}
            setColor={setColor}
            menuState={menuState}
            setMenuState={setMenuState}
            scolor={color ? true : false}
            lang={lang}
            dimensions={dimensions}
          />
          <ul className="menu-lang">
            <li className={lang === "PT" ? "active" : ""}>
              {lang === "PT" ? "PT" : <Link to="/">PT</Link>}
            </li>
            <li className={lang === "ES" ? "active" : ""}>
              {lang === "ES" ? "ES" : <Link to="/es/">ES</Link>}
            </li>
          </ul>
          <Toggle
            scolor={color ? true : false}
            onClick={() => setMenuState(!menuState)}
          >
            <span className="menuToggle span--top"></span>
            <span className="menuToggle span--center"></span>
            <span className="menuToggle span--bottom"></span>
          </Toggle>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
