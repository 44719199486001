import React from "react";

const Facebook = (props) => {
  const { color } = props;
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="11"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00012207 6.25e-05H10.3419V20H0.00012207V6.25e-05Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.29887 11.0526V20.0001H6.65137V11.0951H9.62262L10.342 7.38256H6.68262V4.65944C6.68262 4.28319 6.88325 3.78069 7.49262 3.78069H9.60762V6.25e-05H5.65825C4.29512 6.25e-05 2.28387 1.67819 2.28387 3.46256V7.27694H0.00012207V11.0369L2.29887 11.0526Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Facebook;
