import React from "react";

import { black } from "theme/colors";
import { FooterWrapper, Smedia, Copy, Privacy } from "./Footer.styles";
import { getYear } from "utils/mixins";

import { Facebook } from "../icons";
import { Link } from "gatsby";

const Footer = (props) => {
  const { lang } = props;

  return (
    <FooterWrapper className="container">
      <Privacy>
        <Link to={lang === "PT" ? "/politica-de-cookies" : "/es/politica-de-cookies"}>
          Politica de Cookies
        </Link>
      </Privacy>

      <Copy>© {getYear()} Adalmáris - Mobiliário, Lda</Copy>
      <Smedia>
        <li>
          <a
            rel="noreferrer"
            href="https://www.facebook.com/Adalmaris-Mobili%C3%A1rio-LD%C2%AA-Fabrico-pr%C3%B3prio-de-todo-o-tipo-de-mobili%C3%A1rio-165315866849825/"
            aria-label="Social Media Facebook"
          >
            <Facebook color={black.default} />
          </a>
        </li>
      </Smedia>
    </FooterWrapper>
  );
};

export default Footer;
