import React from "react";

import { useCookies } from "react-cookie";
import Link from "gatsby-link";

import { CookieContainer } from "./CookieBar.styles";

const CookieBar = (props) => {
  //PROPS
  const { lang } = props;
  //PROPS

  //COOKIES
  const [cookies, setCookie] = useCookies(["hasCookiesDEV"]);
  //COOKIES

  //FUNCS
  const handleCookie = () => {
    setCookie("hasCookiesDEV", "true", { path: "/" });
  };
  //FUNCS

  if (cookies.hasCookiesDEV !== "true") {
    return (
      <CookieContainer>
        <div className="container">
          <p>
            {lang === "PT"
              ? "As cookies servem para ajudar o website a funcionar corretamente e melhorar a sua experiência de navegação."
              : " Las cookies sirven para ayudar a que el sitio web funcione correctamente y mejorar su experiencia de navegación."}{" "}
            <Link
              to={
                lang === "PT"
                  ? "/politica-de-cookies/"
                  : "/es/politica-de-cookies"
              }
            >
              {lang === "PT"
                ? "Para mais informações clique aqui"
                : "Para más información, haga clic aquí"}
            </Link>
            .
          </p>
          <button onClick={() => handleCookie()}>
            {lang === "PT" ? "Aceitar Cookies" : "Acepto Cookies"}
          </button>
        </div>
      </CookieContainer>
    );
  } else {
    return null;
  }
};

export default CookieBar;
