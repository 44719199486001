import React, { useEffect } from "react";
import { NavColumn, Wrapper } from "./Nav.styles";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import gsap from "gsap";
import { brown, white } from "theme/colors";

const tl = gsap.timeline();

const Nav = (props) => {
  //PROPS
  const {
    scolor,
    lang,
    scrollY,
    dimensions,
    menuState,
    setMenuState,
    setColor,
  } = props;
  //PROPS

  //ANIMS
  const openMenu = () => {
    tl.to(".menuToggle ", {
      duration: 0.22,
      css: { top: 7 },
    })
      .to(".span--top", {
        duration: 0.22,
        rotation: 45,
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        rotation: -45,
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 0,
      })

      .to(`.menuToggle`, {
        duration: 0.15,
        delay: -0.25,
        css: {
          backgroundColor: white.default,
        },
      });
  };

  const closeMenu = () => {
    tl.to(".menuToggle", {
      duration: 0.22,
      rotation: 0,
    })
      .to(".span--top", {
        duration: 0.22,
        css: { top: 0 },
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        css: { top: 14 },
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 1,
      })

      .to(`.menuToggle`, {
        duration: 0.15,
        delay: -0.25,
        css: {
          backgroundColor: scrollY > 100 ? brown.default : white.default,
        },
      });

    if (scrollY > 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  //ANIMS

  //USEFFECTS
  useEffect(() => {
    if (menuState) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [menuState]);

  useEffect(() => {
    setMenuState(false);
  }, [dimensions]);

  //USEFFECTS

  return (
    <Wrapper className="navContainer" menuState={menuState}>
      <ul className="menu">
        <NavColumn
          className="nav-column"
          scolor={scolor}
          onClick={() => menuState && setMenuState(false)}
        >
          <AnchorLink
            stripHash
            to={lang === "PT" ? "/#aboutus" : "/es/#aboutus"}
          >
            {lang === "PT" ? "Sobre Nós" : "Sobre nosotros"}
          </AnchorLink>
        </NavColumn>
        <NavColumn
          className="nav-column"
          scolor={scolor}
          onClick={() => menuState && setMenuState(false)}
        >
          <AnchorLink
            stripHash
            to={lang === "PT" ? "/#services" : "/es/#services"}
          >
            {lang === "PT" ? "Serviços" : "Servicios"}
          </AnchorLink>
        </NavColumn>
        <NavColumn
          className="nav-column"
          scolor={scolor}
          onClick={() => menuState && setMenuState(false)}
        >
          <AnchorLink
            stripHash
            to={lang === "PT" ? "/#production" : "/es/#production"}
          >
            {lang === "PT" ? "O Fabrico" : "La fabricación"}
          </AnchorLink>
        </NavColumn>
        <NavColumn
          className="nav-column"
          scolor={scolor}
          onClick={() => menuState && setMenuState(false)}
        >
          <AnchorLink
            stripHash
            to={lang === "PT" ? "/#showroom" : "/es/#showroom"}
          >
            {lang === "PT" ? "Showroom" : "Showroom"}
          </AnchorLink>
        </NavColumn>
        <NavColumn
          className="nav-column"
          scolor={scolor}
          onClick={() => menuState && setMenuState(false)}
        >
          <AnchorLink
            stripHash
            to={lang === "PT" ? "/#contact" : "/es/#contact"}
          >
            {lang === "PT" ? "Contactos" : "Contactos"}
          </AnchorLink>
        </NavColumn>
      </ul>
    </Wrapper>
  );
};

export default Nav;
